'use client';

import { useSearchParams } from 'next/navigation';
import { StoryblokReactComponent } from './componentsMap';
import { CapsulaReportStoryblok } from '@/types/types-storyblok';
import { PropsWithChildren, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { CapsulaSession } from '@/capsula';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import { makeRichTextRenderOptions } from '../utils/richText';
import { Lora } from 'next/font/google';
import clsx from 'clsx';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokCapsulaReport: StoryblokReactComponent<CapsulaReportStoryblok> = ({ component, story }) => {
  return (
    <Suspense>
      <Report story={story} component={component} />
    </Suspense>
  );
};

export const Report: StoryblokReactComponent<CapsulaReportStoryblok> = ({ component, story }) => {
  const searchParams = useSearchParams();
  const sessionId = searchParams.get(component.sessionParamName);

  const [sessionData, setSessionData] = useState<CapsulaSession | undefined>(undefined);
  const [loading, setLoading] = useState(typeof sessionId === 'string');

  useEffect(() => {
    if (!sessionId) return;
    const getSessionsData = async () => {
      try {
        const res = await fetch(`/api/capsula/session?sessionId=${sessionId}`);
        const data = (await res.json()) as CapsulaSession;
        setSessionData(data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    getSessionsData();
  }, [sessionId]);

  const bodyComposition = sessionData?.checkupList?.bodyCompositionCheck;
  const age = sessionData?.checkupList?.ageCheck;
  const vascular = sessionData?.checkupList?.vascularCheck;
  const wellbeing = sessionData?.checkupList?.wellbeingHRVCheck;
  const translations = useMemo(
    () =>
      component.scoreTranslations?.tbody.reduce(
        // @ts-ignore - tbd in 86413
        (acc, row) => {
          const key = row.body.at(0);
          const value = row.body.at(1);
          if (!key || !value) return acc;
          return {
            ...acc,
            [key.value ?? '']: value.value ?? '',
          };
        },
        {} as Record<string, string>,
      ),
    [component.scoreTranslations],
  );
  const translate = useCallback((key: string) => translations?.[key] ?? key, [translations]);

  if (!sessionId) return null;

  return (
    <section className="flex flex-col gap-7 max-w-section xl:mx-auto">
      {component.title && loading ? (
        <div className="mx-4 md:mx-20 bg-slate-200 rounded-xl animate-pulse ">
          <span className="opacity-0 text-3xl" aria-hidden>
            {render(
              component.title,
              makeRichTextRenderOptions(story, {
                markResolvers: {
                  [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
                },
              }),
            )}
          </span>
        </div>
      ) : (
        component.title && (
          <h2 className="text-3xl mx-auto text-center">
            {render(
              component.title,
              makeRichTextRenderOptions(story, {
                markResolvers: {
                  [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
                },
              }),
            )}
          </h2>
        )
      )}
      <div className="flex flex-row justify-center flex-wrap lg:flex-nowrap px-4 md:px-20 gap-x-5 gap-y-7">
        <ReportCard
          loading={loading}
          data={
            bodyComposition && {
              iconFilename: component.bodyCompositionCheckIcon.filename,
              label: component.bodyCompositionCheckLabel,
              date: bodyComposition.creationDate,
            }
          }
        >
          <span className="text-primary-active text-2xl font-medium capitalize">{translate(bodyComposition?.score ?? '')}</span>
        </ReportCard>
        <ReportCard
          loading={loading}
          data={
            age && {
              iconFilename: component.ageCheckIcon.filename,
              label: component.ageCheckLabel,
              date: age.creationDate,
            }
          }
        >
          <span className="text-primary-active text-2xl font-medium capitalize">{translate(age?.score ?? '')}</span>
        </ReportCard>
        <ReportCard
          loading={loading}
          data={
            wellbeing && {
              iconFilename: component.wellbeignCheckIcon.filename,
              label: component.wellbeignCheckLabel,
              date: wellbeing.creationDate,
            }
          }
        >
          <span className="text-primary-active text-2xl font-medium capitalize">{translate(wellbeing?.score ?? '')}</span>
        </ReportCard>
        {vascular?.indicators.bloodPressure?.diastolicPressure && vascular?.indicators.bloodPressure?.systolicPressure && (
          <ReportCard
            loading={loading}
            data={
              vascular && {
                iconFilename: component.vascularCheckIcon.filename,
                label: component.vascularCheckLabel,
                date: vascular.creationDate,
              }
            }
          >
            <p>
              <span className="text-primary-active text-2xl font-medium capitalize">
                {vascular?.indicators.bloodPressure.diastolicPressure.value}
              </span>{' '}
              min
            </p>
            <p>
              <span className="text-primary-active text-2xl font-medium capitalize">
                {vascular?.indicators.bloodPressure.systolicPressure.value}
              </span>{' '}
              max
            </p>
          </ReportCard>
        )}
      </div>
      {loading && (
        <div className="mx-auto rounded-xl py-4 px-16 bg-slate-200 animate-pulse">
          <span className="opacity-0 text-lg font-semibold" aria-hidden>
            {component.downloadPdfCtaLabel}
          </span>
        </div>
      )}
      {sessionData && (
        <a
          href={`/api/capsula/report?sessionId=${sessionId}`}
          className="bg-elty-green text-white py-4 px-16 rounded-xl text-center mx-auto mt-7 font-semibold text-lg"
          target="_blank"
        >
          {component.downloadPdfCtaLabel}
        </a>
      )}
    </section>
  );
};

const ReportCard = ({
  loading,
  data,
  children,
}: PropsWithChildren<{
  loading: boolean;
  data?: {
    iconFilename: string;
    label: string;
    date: string;
  };
}>) => {
  if (loading) {
    return <div className="bg-slate-200 h-52 rounded-xl animate-pulse shadow flex-grow w-[calc(50%-0.675rem)] lg:w-1/4"></div>;
  }
  if (!data) return null;
  const date = new Date(data.date);
  return (
    <div className="bg-neutral-surface rounded-xl border p-4 flex flex-col shadow w-[calc(50%-0.675rem)] lg:w-1/4">
      <div className="flex flex-row items-start justify-between mb-2">
        <Image className="size-12" src={data.iconFilename} alt={data.label} {...getImageDimensions({ filename: data.iconFilename })} />
        <span className="text-xs text-neutral-darker">
          {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
        </span>
      </div>
      <span className="mb-3 text-neutral-darker text-sm font-medium leading-[18px]">{data.label}</span>
      <div className="min-h-16 flex flex-col justify-center">{children}</div>
    </div>
  );
};
