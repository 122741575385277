import { clientEnv } from '@/env/client';
import { RichtextStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import Link from 'next/link';
import { StoryblokStory } from 'storyblok-generate-ts';
import { MARK_LINK, RenderOptions } from 'storyblok-rich-text-react-renderer';

export const richTextToString = (richText: RichtextStoryblok): string => {
  if (richText.type === 'text') {
    return richText.text ?? '';
  }
  return richText.content?.map(blok => richTextToString(blok)).join('') ?? '';
};

export const makeRichTextRenderOptions = (story: StoryblokStory<StoryblokComponent>, options?: RenderOptions): RenderOptions => {
  return {
    ...options,
    markResolvers: {
      ...options?.markResolvers,
      [MARK_LINK]: (children, mark) => {
        let href = '';

        if (mark.href) {
          if (!mark.href.startsWith('/')) {
            mark.href = '/' + mark.href;
          }

          // medico link in elty page
          if (mark.href.startsWith('/medico') && !story.full_slug.startsWith('medico')) {
            href = `${clientEnv.NEXT_PUBLIC_ELTY_MMG_PUBLIC_URL}${mark.href.replace('/medico', '')}`;
          }
          // medico link in medico page
          else if (mark.href.startsWith('/medico') && story.full_slug.startsWith('medico')) {
            href = mark.href.replace('/medico', '');
          }
          // elty link in medico page
          else if (!mark.href.startsWith('/medico') && story.full_slug.startsWith('medico')) {
            href = `${clientEnv.NEXT_PUBLIC_ELTY_PUBLIC_URL}${mark.href}`;
          } else {
            href = mark.href;
          }
        }

        if (mark.anchor) {
          href += `#${mark.anchor}`;
        }

        if (options?.markResolvers?.[MARK_LINK]) {
          return options.markResolvers[MARK_LINK](children, { ...mark, href, originalHref: mark.href } as any);
        }

        return (
          <Link href={href} target={mark.target}>
            {children}
          </Link>
        );
      },
    },
  };
};
