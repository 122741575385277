import { getImageDimensions } from '@/storyblok/utils/asset';
import { AuthorStoryblok } from '@/types/types-storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';
import Image from 'next/image';
import { render } from 'storyblok-rich-text-react-renderer';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';
import Link from 'next/link';

export const PostAuthor = ({ author }: { author: StoryblokStory<AuthorStoryblok> }) => {
  const image = author.content.image;
  return (
    <Link className="shadow-1 rounded-xl flex flex-col md:flex-row p-8 gap-8 mx-4" href={`/guide/autori/${author.slug}`}>
      <Image
        className="size-24 rounded-full shadow-lg shadow-black/5"
        src={image.filename}
        alt={image.alt ?? ''}
        {...getImageDimensions(image)}
      />
      <div className="flex flex-col gap-2.5">
        <h2 className="text-neutral-darker font-semibold flex flex-col items-start md:flex-row gap-2.5">
          <span className="bg-neutral-100 p-2 rounded-lg leading-4 text-sm">Autore</span>
          <span className="text-xl font-semibold">{author.content.name}</span>
        </h2>
        <p className="line-clamp-6 md:line-clamp-2 text-neutral-lighter">{render(author.content.bio, makeRichTextRenderOptions(author))}</p>
      </div>
    </Link>
  );
};
