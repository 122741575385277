import { VideoStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { storyblokEditable } from '@storyblok/js';

export const StoryblokVideo: StoryblokReactComponent<VideoStoryblok> = ({ component }) => {
  return (
    <section className="px-4 md:px-20 md:py-8 max-w-section xl:mx-auto" {...storyblokEditable(component)}>
      <video className="rounded-xl" controls poster={component.previewImage?.filename}>
        <source src={component.video.filename} />
      </video>
    </section>
  );
};
