'use client';

import { VcVoucherCodeFormStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { storyblokEditable } from '@storyblok/js';
import { FormEventHandler } from 'react';
import { clientEnv } from '@/env/client';

export const StoryblokVcVoucherCodeForm: StoryblokReactComponent<VcVoucherCodeFormStoryblok> = ({ component }) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();
    const voucher = data.get('voucher');
    if (typeof voucher !== 'string') return;
    const response = await fetch(`${clientEnv.NEXT_PUBLIC_MARKETING_API_URL}/api/v1/marketing/vc-voucher`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ voucher }),
    });
    if (response.status !== 200) {
      console.error('Error submitting voucher code');
      return;
    }
    window.location.href = component.formAction;
  };

  return (
    <section
      className="relative flex flex-col gap-10 pt-11 lg:items-start lg:pt-28 max-w-section xl:mx-auto"
      {...storyblokEditable(component)}
    >
      <div className="flex flex-col w-full items-center">
        {component.title && (
          <h2 className="font-semibold text-neutral-darker text-2xl md:text-3xl px-9 text-center relative z-[2]   xl:max-w-screen-xl xl:w-full xl:inline-block xl:mx-auto xl:text-4xl">
            {component.title}
          </h2>
        )}
        {component.subtitle && (
          <h3 className="text-neutral-darker font-medium text-xl  lg:font-medium px-14 text-center relative z-[2]  xl:max-w-screen-xl xl:w-full xl:inline-block xl:mx-auto mt-4 ">
            {component.subtitle}
          </h3>
        )}
      </div>
      <div className="xl:max-w-screen-xl w-full flex flex-row relative justify-center pb-10">
        <div className="bg-white p-6 flex flex-col gap-6 relative border border-black/5 rounded-lg ">
          <form className="flex flex-col gap-3 md:grid md:p-1 md:grid-cols-[1fr,min-content]" onSubmit={handleSubmit}>
            <div className="flex-1 flex flex-col gap-1">
              {component.inputLabel && (
                <label className="text-sm font-medium" htmlFor="service">
                  {component.inputLabel}
                </label>
              )}
              <input name={component.inputName} type="text" className="bg-background-neutral px-3 rounded-lg py-3 w-full lg:w-86 xl:w-96" />
            </div>
            <div className="flex flex-col justify-end">
              <button
                className="transition-colors whitespace-nowrap py-3 bg-elty-blue text-white font-semibold rounded-lg flex-shrink-0 md:px-3.5 hover:bg-brandBlue-600 focus:bg-brandBlue-600"
                type="submit"
                aria-label={component.callToActionLabel}
              >
                <span>{component.callToActionLabel}</span>
              </button>
            </div>
            {component.bottomLabel && <p className="md:col-span-3 text-text-body text-sm">{component.bottomLabel}</p>}
          </form>
        </div>
      </div>
    </section>
  );
};
