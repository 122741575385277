import { SectionWithBulletpointsAndImageStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import Image from 'next/image';
import { Lora } from 'next/font/google';
import clsx from 'clsx';
import Link from 'next/link';
import { resolveWebLink } from '../utils/link';
import { DAVINCI_CDN } from '@/routes';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '../utils/richText';
import { getImageDimensions } from '@/storyblok/utils/asset';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

const darkBlueGradient = 'bg-[linear-gradient(110deg,_rgba(15,_50,_80,_0.95)_2%,_rgba(3,_19,_35,_0.91)_45%,_rgba(15,_50,_80,_0.88)_100%)]';

export const SectionWithBulletpointsAndImage: StoryblokReactComponent<SectionWithBulletpointsAndImageStoryblok> = ({
  component,
  story,
}) => {
  const callToAction = component.callToAction?.at(0);
  return (
    <section
      className={clsx(
        `flex flex-col gap-10 mx-4 md:mx-10 lg:mx-20 py-10 rounded-xl shadow-2 lg:grid lg:grid-cols-2 md:px-10 lg:px-20 lg:py-14 lg:gap-x-[4.5rem] lg:gap-y-16 max-w-section xl:mx-auto`,
        component.backgroundColor === 'darkBlueGradient' ? darkBlueGradient : 'bg-white',
      )}
      {...storyblokEditable(component)}
    >
      <div
        className={clsx(
          'order-1 flex flex-col items-start',
          component?.contentLayout === 'contentRight' ? 'lg:col-start-2' : 'lg:col-start-1',
        )}
      >
        {component.badge && (
          <span
            className={clsx(
              'mx-auto lg:mx-0 px-5 py-1 font-semibold rounded-lg mb-16 inline-block',
              component.backgroundColor === 'darkBlueGradient' ? 'bg-white text-elty-blue' : 'bg-brandBlue-400 text-white',
            )}
          >
            {component.badge}
          </span>
        )}
        <h2
          className={clsx(
            'px-4 text-title-sm text-center md:order-2 md:mx-auto lg:row-start-1 lg:w-full lg:text-left lg:px-0 lg:leading-tight',
            component.backgroundColor === 'darkBlueGradient' && 'text-white',
          )}
        >
          {render(
            component.title,
            makeRichTextRenderOptions(story, {
              markResolvers: {
                [MARK_BOLD]: children => <span className={clsx(lora.className, 'text-elty-green')}>{children}</span>,
              },
            }),
          )}
        </h2>
      </div>
      <div
        className={clsx(
          'relative w-full aspect-[1/0.85] md:order-1 lg:row-start-1 lg:row-span-3 lg:aspect-auto order-3',
          component?.contentLayout === 'contentRight' ? 'lg:col-start-1' : 'lg:col-start-2',
        )}
      >
        <Image
          className="rounded-xl object-contain md:hidden"
          src={component.mobileImage.filename}
          alt={component.mobileImage.alt ?? ''}
          fill
        />
        <Image
          className="rounded-xl object-contain hidden md:block lg:hidden"
          src={component.tabletImage.filename}
          alt={component.tabletImage.alt ?? ''}
          fill
        />
        <Image
          className={clsx(
            'object-cover rounded-xl hidden lg:block',
            // component?.contentLayout === 'contentRight' ? 'object-left' : 'object-right',
          )}
          src={component.desktopImage.filename}
          alt={component.desktopImage.alt ?? ''}
          fill
        />
      </div>
      <div className="flex flex-col md:order-3 lg:row-start-2 order-4">
        {component.textContent && (
          <p
            className={clsx(
              'text-center px-4 md:px-0 md:text-left text-lg',
              component.backgroundColor === 'darkBlueGradient' ? 'text-white' : 'text-neutral-lighter',
            )}
          >
            {component.textContent}
          </p>
        )}
        {!!component.bulletPoints?.length && (
          <ul className={clsx('flex flex-col gap-7', component.bulletPoints.length && component.textContent !== undefined && 'mt-6')}>
            {component.bulletPoints.map(bulletPoin => (
              <li key={bulletPoin._uid} className="px-4 md:px-20 lg:px-0 flex flex-row items-center gap-5 md:text-lg md:leading-snug">
                <Image
                  className="md:hidden h-8 w-8"
                  src={bulletPoin.image.filename}
                  alt={bulletPoin.image.alt ?? ''}
                  width={30}
                  height={30}
                />
                <Image
                  className="hidden md:block h-10 w-10"
                  src={bulletPoin.image.filename}
                  alt={bulletPoin.image.alt ?? ''}
                  width={48}
                  height={48}
                />
                <div className={clsx(component.backgroundColor === 'darkBlueGradient' ? 'text-white' : 'text-text-body')}>
                  {render(
                    bulletPoin.content,
                    makeRichTextRenderOptions(story, {
                      markResolvers: {
                        [MARK_BOLD]: children => <span className="font-medium">{children}</span>,
                      },
                    }),
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div
        className={clsx(
          'flex flex-row items-end justify-center md:order-4 lg:row-start-3 lg:justify-start',
          component.ctaMobilePosition === 'top' ? 'order-2' : 'order-5',
        )}
      >
        {callToAction?.component === 'Link' && callToAction.link && (
          <Link
            className={clsx(
              'rounded-lg py-4 px-14 md:px-6 text-lg font-medium transition-colors',
              component.backgroundColor === 'darkBlueGradient'
                ? 'bg-white text-elty-blue border border-transparent hover:border-white hover:bg-transparent hover:text-white'
                : 'bg-elty-blue text-white hover:bg-primary-hover',
            )}
            href={resolveWebLink(callToAction.link, { component, story })}
          >
            {callToAction.title}
          </Link>
        )}
        {callToAction?.component === 'appStoreButton' && (
          <div className="flex flex-row gap-3">
            <Link
              href="https://apps.apple.com/it/app/davincisalute/id1469087727"
              target="_blank"
              rel="noopener noreferrer"
              className="h-12"
            >
              <Image
                src={`${DAVINCI_CDN}/elty/download-on-app-store.png`}
                width={184}
                height={56}
                alt="Download on App Store"
                className="h-12 w-auto"
              />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.davincisalute.app"
              target="_blank"
              rel="noopener noreferrer"
              className="h-12"
            >
              <Image
                src={`${DAVINCI_CDN}/elty/download-on-google-play.png`}
                height={56}
                width={183}
                alt="Download on Google Play"
                className="h-12 w-auto"
              />
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};
