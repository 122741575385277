'use client';

import { BookingCtaStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Link from 'next/link';
import { makePrestazioneRoute, makePrestazioneRouteWithoutCity } from '@/routes';
import Image from 'next/image';
import chevronRightIcon from '@/assets/service-card-chevron-right-icon.svg';
import clsx from 'clsx';
import { fireGTMEvent } from '@/gtm';
import { storyblokEditable } from '@storyblok/js';
import { richTextToString } from '../utils/richText';

const OTHER_CITIES = Symbol.for('other-cities');

export const StoryblokBookingCta: StoryblokReactComponent<BookingCtaStoryblok> = ({ component, story }) => {
  const cities: Array<typeof OTHER_CITIES | string> = component.cities ?? [];
  if (cities.length % 2 !== 0) {
    cities?.push(OTHER_CITIES);
  }
  return (
    <div
      className="bg-brandGreen-50 border rounded-3xl shadow-1 p-8 px-10 lg:px-8 flex flex-col gap-6 mx-4 relative overflow-hidden"
      {...storyblokEditable(component)}
    >
      <h2 className="text-neutral-darker text-[1.75rem] font-[550] relative z-10 flex flex-col lg:flex-row items-center gap-2 text-center lg:text-left">
        <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.601562" width="33.7966" height="33.7966" rx="4.50568" fill="#0BBCA7" />
          <path
            d="M8 17.6016H11M17 8.60156V11.6016M12.8 13.4016L10.6 11.2016M21.2 13.4016L23.4 11.2016M12.8 21.8016L10.6 24.0016M17 17.6016L26 20.6016L22 22.6016L20 26.6016L17 17.6016Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {richTextToString(component.title)}
      </h2>
      <ul
        className={clsx(
          'flex flex-col lg:grid gap-4 relative z-10',
          cities.length === 6 ? 'lg:grid-cols-3' : cities.length === 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-4',
        )}
      >
        {cities.map(city => (
          <li
            className="bg-white rounded-xl"
            aria-label={city === OTHER_CITIES ? 'altre città' : city}
            key={city === OTHER_CITIES ? 'other-cities' : city}
          >
            <Link
              href={
                city === OTHER_CITIES
                  ? component.speciality
                    ? makePrestazioneRouteWithoutCity(component.speciality)
                    : `/cerca?service=${encodeURI(component.speciality || component.manualSpeciality || '')}`
                  : makePrestazioneRoute(city, component.speciality || component.manualSpeciality || '')
              }
              onClick={() => {
                fireGTMEvent('blog_click_città', { city: city === OTHER_CITIES ? 'Altre' : city, speciality: component.speciality });
              }}
              className="flex flex-row items-center gap-3 py-3 px-5 transition-colors rounded-xl shadow-sm border group hover:border-brandGreen-500 h-full"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.7134 13.8811L11.1775 17.4169C11.0229 17.5717 10.8393 17.6945 10.6372 17.7783C10.435 17.862 10.2184 17.9052 9.99961 17.9052C9.78082 17.9052 9.56417 17.862 9.36206 17.7783C9.15994 17.6945 8.97632 17.5717 8.82169 17.4169L5.28502 13.8811C4.35271 12.9487 3.7178 11.7608 3.4606 10.4676C3.20339 9.17443 3.33543 7.83401 3.84003 6.61585C4.34462 5.3977 5.1991 4.35653 6.29542 3.624C7.39174 2.89147 8.68066 2.50049 9.99919 2.50049C11.3177 2.50049 12.6066 2.89147 13.703 3.624C14.7993 4.35653 15.6538 5.3977 16.1584 6.61585C16.6629 7.83401 16.795 9.17443 16.5378 10.4676C16.2806 11.7608 15.6457 12.9487 14.7134 13.8811V13.8811Z"
                  stroke="#0D9488"
                  strokeWidth="2.16428"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.7678 10.9343C12.2366 10.4654 12.5 9.82955 12.5 9.1665C12.5 8.50346 12.2366 7.86758 11.7678 7.39874C11.2989 6.9299 10.663 6.6665 10 6.6665C9.33696 6.6665 8.70107 6.9299 8.23223 7.39874C7.76339 7.86758 7.5 8.50346 7.5 9.1665C7.5 9.82955 7.76339 10.4654 8.23223 10.9343C8.70107 11.4031 9.33696 11.6665 10 11.6665C10.663 11.6665 11.2989 11.4031 11.7678 10.9343Z"
                  stroke="#0D9488"
                  strokeWidth="2.16428"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="capitalize flex-grow text-neutral-darker font-semibold">{city === OTHER_CITIES ? 'Altre' : city}</span>
              <Image
                className="transition-transform translate-x-0 group-hover:translate-x-1"
                src={chevronRightIcon}
                alt="icona freccia destra"
                aria-hidden
              />
            </Link>
          </li>
        ))}
      </ul>

      <svg
        width="428"
        height="231"
        viewBox="0 0 428 231"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-0 top-0 h-full hidden lg:block"
      >
        <g clipPath="url(#clip0_6739_6700)">
          <rect
            opacity="0.5"
            x="274.431"
            y="511.981"
            width="618.961"
            height="1106.71"
            rx="309.48"
            transform="rotate(-122.414 274.431 511.981)"
            fill="#CFEAE7"
          />
          <rect
            opacity="0.5"
            x="367.1"
            y="428.177"
            width="539.198"
            height="979.548"
            rx="269.599"
            transform="rotate(-122.414 367.1 428.177)"
            fill="#9ED4CF"
          />
          <rect
            x="451.982"
            y="324.144"
            width="407.087"
            height="739.543"
            rx="203.543"
            transform="rotate(-122.414 451.982 324.144)"
            fill="#9ED4CF"
          />
        </g>
        <defs>
          <clipPath id="clip0_6739_6700">
            <rect width="437" height="322" fill="white" transform="translate(0.5 -45.7412)" />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="320"
        height="438"
        viewBox="0 0 320 438"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full block absolute left-0 bottom-0 lg:hidden"
        preserveAspectRatio="none"
      >
        <g clipPath="url(#clip0_6738_6507)">
          <rect
            opacity="0.5"
            x="-236.723"
            y="317.209"
            width="618.961"
            height="1106.71"
            rx="309.48"
            transform="rotate(-32.4136 -236.723 317.209)"
            fill="#CFEAE7"
          />
          <rect
            opacity="0.5"
            x="-152.918"
            y="367.207"
            width="539.198"
            height="979.548"
            rx="269.599"
            transform="rotate(-32.4136 -152.918 367.207)"
            fill="#9ED4CF"
          />
          <rect
            x="-48.885"
            y="452.089"
            width="407.087"
            height="739.543"
            rx="203.543"
            transform="rotate(-32.4136 -48.885 452.089)"
            fill="#9ED4CF"
          />
        </g>
        <defs>
          <clipPath id="clip0_6738_6507">
            <rect width="437" height="322" fill="white" transform="translate(321 0.606934) rotate(90)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
