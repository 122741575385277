import { StoryblokReactComponent } from '@/storyblok/components/componentsMap';
import { BlogPostsRelatedLinksStoryblok } from '@/types/types-storyblok';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';

export const RelatedLinks: StoryblokReactComponent<BlogPostsRelatedLinksStoryblok> = ({ component, story }) => {
  return (
    <div className="flex flex-col gap-4 md:grid md:grid-cols-3 col-span-2 px-5">
      {component.columns?.map(column => (
        <div className="flex flex-col gap-2 border-b pb-2 md:border-none last-of-type:border-none" key={column._uid}>
          {column.title && (
            <h4 className="font-semibold">
              {column.title}
              {column.showAllLink && (
                <Link
                  href={resolveWebLink(column.showAllLink, { story })}
                  className="flex-row items-center group gap-0.5 inline-flex transition-colors text-sm text-elty-green ml-2 hover:text-brandGreen-600"
                >
                  Vedi tutti
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="size-4 group-hover:translate-x-0.5 transition-transform"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </Link>
              )}
            </h4>
          )}
          <ul className="flex flex-col gap-2">
            {column.links?.map(link =>
              link.link && link.title ? (
                <li className="line-clamp-2 text-sm transition-colors text-neutral-lighter hover:text-neutral-darker" key={link._uid}>
                  <Link href={resolveWebLink(link.link, { story })}>{link.title}</Link>
                </li>
              ) : null,
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};
