import { Lora } from 'next/font/google';
import { StoryblokReactComponent } from '../componentsMap';
import { SmallCardsSectionStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';

import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx';
import { StoryblokComponent } from '../storyblokComponent';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokSmallCardsSection: StoryblokReactComponent<SmallCardsSectionStoryblok> = ({ component, story }) => {
  return (
    <section
      className={clsx(component.background === 'lightGray' ? 'bg-[#F3F8F8]' : 'bg-white', 'px-4 md:px-20 shadow-1 py-14 lg:py-20')}
      {...storyblokEditable(component)}
    >
      <h2 className="flex flex-col items-center text-title text-center">
        {component.titleIcon?.filename && (
          <Image
            className="hidden lg:inline-block mb-4"
            src={component.titleIcon.filename}
            alt={component.titleIcon.alt ?? ''}
            aria-hidden="true"
            height={28}
            width={28}
          />
        )}
        <span>
          {render(
            component.title,
            makeRichTextRenderOptions(story, {
              markResolvers: {
                [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
              },
            }),
          )}
        </span>
      </h2>
      {component.titleSeparator && <hr className="mt-5 border-elty-green mb-14 md:w-1/2 md:mx-auto lg:border-none" />}
      <ul
        className={clsx(
          'flex flex-col gap-4 md:gap-8 lg:gap-10 max-w-section mx-auto',
          'md:grid md:grid-cols-2',
          !component.titleSeparator && 'mt-20',
          component.desktopCardsDisposition === 'row' && 'lg:flex lg:flex-row',
          component.desktopCardsDisposition === 'grid' && 'lg:max-w-5xl lg:mx-auto',
        )}
      >
        {component.cards?.map(card => <StoryblokComponent story={story} key={card._uid} component={card} />)}
      </ul>
    </section>
  );
};
