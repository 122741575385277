'use client';

import { NewsletterBannerStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent, StoryblokReactComponentProps } from '../componentsMap';
import { useFormStatus } from 'react-dom';
import { submit } from './actions';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import clsx from 'clsx';

const newsletterBannerContext = React.createContext<{ onSubmitted: () => void }>({
  onSubmitted: () => {},
});

export const NewsletterBannerContainer = ({
  component,
  children,
}: PropsWithChildren<StoryblokReactComponentProps<NewsletterBannerStoryblok>>) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!component.required) return;
    if (window.localStorage.getItem('already-susbcribed-to-newsletter') !== null) return;
    setTimeout(
      () => {
        setShow(true);
      },
      component.requiredTimeBeforeShow ? parseInt(component.requiredTimeBeforeShow) : 5000,
    );
  }, [component.requiredTimeBeforeShow, component.required]);

  const context = {
    onSubmitted: () => {
      window.localStorage.setItem('already-susbcribed-to-newsletter', 'true');
      setShow(false);
    },
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show]);

  if (!component.required) {
    return <newsletterBannerContext.Provider value={context}>{children}</newsletterBannerContext.Provider>;
  }

  return (
    <newsletterBannerContext.Provider value={context}>
      <div
        id="banner-overlay"
        className={clsx(
          'fixed z-50 h-screen w-screen flex flex-col justify-center items-center bg-black/40 top-0 left-0',
          !show && 'hidden',
        )}
      >
        {children}
      </div>
    </newsletterBannerContext.Provider>
  );
};

export const NewsletterBannerForm: StoryblokReactComponent<NewsletterBannerStoryblok> = ({ component, story }) => {
  const [state, setState] = useState<Awaited<ReturnType<typeof submit>> | undefined>(undefined);
  const { onSubmitted } = useContext(newsletterBannerContext);

  const action = async (data: FormData) => {
    const res = await submit(state, data);
    onSubmitted();
    setState(res);
  };

  return (
    <form action={action} className="my-14">
      <div className="bg-white rounded-full flex flex-row items-center relative max-w-lg shrink-0 mx-auto">
        <MailIcon className="mx-3 shrink-0 my-1 absolute left-3" />
        <input required type="hidden" name="formId" value={component._uid} />
        <input required type="hidden" name="slug" value={story.full_slug} />
        <input
          required
          type="email"
          name="email"
          className="w-full rounded-full pl-16 h-14 text-black pr-20 focus-visible:outline-elty-blue"
          placeholder="Inserisci la tua mail"
        />
        <SubmitButton disabled={state?.success} label={component.ctaText || 'Iscriviti'} />
      </div>
      {state?.success === true && (
        <div className="rounded-full w-full text-white text-lg py-1 flex flex-row items-center justify-center px-2 mt-3">
          <span className="inline-block mx-auto rounded text-black text-xs bg-neutral-50 py-2 px-3 shadow-[0px_0px_5px_0px_#AAFFF5] leading-none">
            {component.successMessage}
          </span>
        </div>
      )}
      {state?.error !== undefined && (
        <div className="rounded-full w-full text-white text-lg py-1 flex flex-row items-center justify-center px-2 mt-3">
          <span className="inline-block mx-auto rounded text-error text-xs bg-neutral-50 py-2 px-3 shadow-[0px_0px_5px_0px_#FF9F9F] leading-none">
            {component.errorMessage}
          </span>
        </div>
      )}
    </form>
  );
};

const SubmitButton = ({ disabled, label }: { disabled?: boolean; label: string }) => {
  const { pending } = useFormStatus();
  return (
    <button
      type="submit"
      className="aspect-square md:aspect-auto flex justify-center items-center bg-elty-blue hover:bg-brandBlue-400 transition-colors duration-250 rounded-full shrink-0  my-1 disabled:opacity-70 absolute right-1"
      disabled={pending || disabled}
      title={label}
    >
      <div className="size-12 md:w-auto md:px-6 flex justify-center items-center py-3">
        <ButtonIcon className="md:hidden" />
        <span className="hidden md:inline-block">{label}</span>
      </div>
    </button>
  );
};

const MailIcon = ({ className }: { className?: string }) => (
  <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.25C0 1.59315 1.34315 0.25 3 0.25H18C19.6569 0.25 21 1.59315 21 3.25V13.75C21 15.4069 19.6569 16.75 18 16.75H3C1.34315 16.75 0 15.4069 0 13.75V3.25ZM1.5 3.25V3.49271C1.5 4.0136 1.77023 4.4972 2.21385 4.7702L9.71385 9.38558C10.196 9.68227 10.804 9.68227 11.2861 9.38558L18.7861 4.7702C19.2298 4.4972 19.5 4.0136 19.5 3.49271V3.25C19.5 2.42157 18.8284 1.75 18 1.75H3C2.17157 1.75 1.5 2.42157 1.5 3.25ZM19.5 6.09217L12.0723 10.6631C11.1081 11.2564 9.89193 11.2564 8.92771 10.6631L1.5 6.09217V13.75C1.5 14.5784 2.17157 15.25 3 15.25H18C18.8284 15.25 19.5 14.5784 19.5 13.75V6.09217Z"
      fill="#07142F"
    />
  </svg>
);

const ButtonIcon = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.30732 2.19801C4.50071 2.00154 4.7863 1.92701 5.05104 2.00391C11.7497 3.94982 17.9704 7.01778 23.4963 10.9904C23.6923 11.1313 23.8085 11.358 23.8085 11.5994C23.8085 11.8408 23.6923 12.0674 23.4963 12.2083C17.9704 16.181 11.7497 19.2491 5.05114 21.195C4.7864 21.2719 4.50081 21.1974 4.30741 21.0009C4.11402 20.8045 4.044 20.5177 4.12507 20.2542L6.78803 11.5996L4.12498 2.9447C4.04391 2.68121 4.11392 2.39448 4.30732 2.19801ZM8.12667 12.3496L5.97341 19.3477C11.6525 17.55 16.9616 14.9196 21.7575 11.5994C16.9616 8.27923 11.6524 5.64883 5.97331 3.85123L8.12667 10.8496L15.073 10.8496C15.4872 10.8496 15.823 11.1854 15.823 11.5996C15.823 12.0138 15.4872 12.3496 15.073 12.3496L8.12667 12.3496Z"
      fill="white"
    />
  </svg>
);
