import { ServiceCardStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import medicalServiceIcon from '@/assets/medical-services-icon.svg';
import locationMarkerIcon from '@/assets/location-marker-icon.svg';
import chevronRightIcon from '@/assets/service-card-chevron-right-icon.svg';
import Image from 'next/image';
import { StoryblokReactComponent } from '../componentsMap';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { storyblokEditable } from '@storyblok/js';
import { StoryblokStory } from 'storyblok-generate-ts';
import clsx from 'clsx';
import { getImageDimensions } from '@/storyblok/utils/asset';

const makeCardLink = (card: ServiceCardStoryblok, story: StoryblokStory<StoryblokComponent>): string => {
  if (card?.clickable === false) return '';
  if (card.link && (card.link.url || card.link.cached_url)) return resolveWebLink(card.link, { component: card, story: story });
  if (card.location && card.service)
    return `/cerca?location=${encodeURIComponent(card.location?.toLowerCase())}&service=${encodeURIComponent(card.service.toLowerCase())}`;
  return '';
};

export const StoryblokServiceCard: StoryblokReactComponent<ServiceCardStoryblok> = ({ component: card, story }) => {
  const serviceIcon = card.serviceIcon?.filename ? { src: card.serviceIcon.filename, height: 24, width: 24 } : medicalServiceIcon;
  const locationIcon = card.locationIcon?.filename ? { src: card.locationIcon.filename, height: 24, width: 24 } : locationMarkerIcon;

  if (!card.location || !card.service) return null;
  const Container = card.clickable !== false && card.location ? Link : 'div';
  return (
    <li key={card._uid} className="flex-grow group" {...storyblokEditable(card)}>
      {card.location && (
        <Container
          href={makeCardLink(card, story)}
          className={clsx(
            'flex flex-row items-center py-3 px-5 border transition-colors  rounded-xl bg-white shadow-1 border-black/5',
            card.clickable && 'group-focus:border-elty-green group-hover:border-elty-green',
          )}
        >
          <div className="flex-grow flex flex-col gap-2">
            <div className="flex flex-row items-center gap-3">
              <Image className="size-6" src={serviceIcon} alt="icona servizio medico" aria-hidden />
              <span className="font-medium" aria-label="prestazione">
                {card.service}
              </span>
            </div>
            <div className="flex flex-row items-center gap-3 text-text-body text-sm">
              <Image className="size-6" src={locationIcon} alt="icona posizione" aria-hidden />
              <span aria-label="posizione">{card.location}</span>
            </div>
          </div>
          {card.clickable && (
            <Image
              className="transition-transform translate-x-0 group-hover:translate-x-2"
              src={chevronRightIcon}
              alt="icona freccia destra"
              aria-hidden
            />
          )}
        </Container>
      )}
    </li>
  );
};
