'use client';

import { BookingCtaSmallStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Image, { StaticImageData } from 'next/image';
import medicalServiceIcon from '@/assets/medical-services-icon.svg';
import locationMarkerIcon from '@/assets/location-marker-icon.svg';
import defaultDesktopImage from '@/assets/booking-cta-small-default-image.png';
import defaultMobileImage from '@/assets/booking-cta-small-default-mobile-image.png';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import { fireGTMEvent } from '@/gtm';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '../utils/richText';
import { getImageDimensions } from '../utils/asset';
import clsx from 'clsx';

export const StoryblokBookingCtaSmall: StoryblokReactComponent<BookingCtaSmallStoryblok> = ({ component, story }) => {
  const mobileImage = component.mobileImage?.filename
    ? { src: component.mobileImage.filename, ...getImageDimensions(component.mobileImage) }
    : defaultMobileImage;
  const desktopImage = component.desktopImage?.filename
    ? { src: component.desktopImage.filename, ...getImageDimensions(component.desktopImage) }
    : defaultDesktopImage;

  const background = !!component.background ? component.background : 'white';
  return (
    <div
      className={clsx(
        'mx-4 shadow-1 rounded-3xl p-6 flex flex-col items-stretch lg:items-start gap-6 relative z-[2] border lg:pr-[14rem]',
        background === 'white' ? 'bg-white' : 'bg-brandGreen-400',
      )}
      {...storyblokEditable(component)}
    >
      {component.mobileImageBeforeContent && (
        <>
          <div className="lg:hidden aspect-square w-full -mb-12"></div>
          <Image className="lg:hidden  absolute top-0 right-0 w-full" src={mobileImage} alt="Medico" aria-hidden />
        </>
      )}
      <div className="flex flex-col gap-3">
        <h2 className={clsx('text-2xl font-[550]', background === 'white' ? 'text-neutral-darker' : 'text-white')}>
          {render(
            component.title,
            makeRichTextRenderOptions(story, {
              markResolvers: {
                [MARK_BOLD]: children => <strong className="font-medium">{children}</strong>,
              },
            }),
          )}
        </h2>
        <p className={clsx('text-lg', background === 'white' ? 'text-neutral-darker' : 'text-white')}>
          Cerca la prestazione medica di cui hai bisogno.
        </p>
      </div>
      <form
        className="flex flex-col gap-3 lg:flex-row lg:p-1 relative z-10"
        action="/cerca"
        onSubmit={e => {
          const formData = new FormData(e.target as HTMLFormElement);
          fireGTMEvent('blog_click_città', { city: formData.get('location'), speciality: formData.get('service') });
        }}
      >
        <div className="flex flex-row flex-grow items-center bg-brandGreen-50 rounded-lg relative">
          <Image className="absolute ml-5 flex-shrink-0" src={medicalServiceIcon} alt="icona servizi medici" aria-hidden="true" />
          <input
            defaultValue={component.speciality || component.manualSpeciality}
            name="service"
            required
            className={clsx(
              'flex-grow pl-14 pr-5 rounded-lg py-3 focus:outline-[#0D9488] focus:outline-1 min-w-48 w-full',
              background === 'white' ? 'bg-brandGreen-50' : 'bg-neutral-surface',
            )}
            placeholder="Es. Psicologo"
          />
        </div>
        <div className="flex flex-row flex-grow items-center bg-brandGreen-50 rounded-lg relative">
          <Image className="absolute ml-5 flex-shrink-0" src={locationMarkerIcon} alt="icona indicatore di posizione" aria-hidden="true" />
          <input
            name="location"
            required
            className={clsx(
              'flex-grow pl-14 pr-5 rounded-lg py-3 focus:outline-[#0D9488] focus:outline-1 min-w-48 w-full',
              background === 'white' ? 'bg-brandGreen-50' : 'bg-neutral-surface',
            )}
            placeholder="Es. Milano"
          />
        </div>
        <button
          className={clsx(
            'transition-colors py-3 text-white text-lg font-semibold rounded-lg lg:py-3 lg:px-4 shadow-lg',
            background === 'white'
              ? 'bg-secondary hover:bg-secondary-hover focus:bg-secondary-hover'
              : 'bg-primary hover:bg-primary-hover focus:bg-primary-hover',
          )}
          type="submit"
          aria-label="Cerca"
        >
          <span>Cerca</span>
        </button>
      </form>

      <Image className="absolute right-0 bottom-0 hidden lg:block h-full w-auto" src={desktopImage} alt="Medico" aria-hidden />
      {!component.mobileImageBeforeContent && (
        <>
          <div className="lg:hidden aspect-square w-full -mt-12"></div>
          <Image className="lg:hidden  absolute bottom-0 right-0 w-full" src={mobileImage} alt="Medico" aria-hidden />
        </>
      )}
    </div>
  );
};
