import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import { StoryblokReactComponent } from './componentsMap';
import { CenteredHeroBannerStoryblok } from '@/types/types-storyblok';
import { makeRichTextRenderOptions } from '../utils/richText';
import { storyblokEditable } from '@storyblok/js';
import { Lora } from 'next/font/google';
import clsx from 'clsx';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';
import Link from 'next/link';
import { resolveWebLink } from '../utils/link';

const lora = Lora({ subsets: ['latin'], weight: 'variable', style: 'italic' });

export const StoryblokCenteredHeroBanner: StoryblokReactComponent<CenteredHeroBannerStoryblok> = ({ story, component }) => {
  const cta = component.cta?.at(0);

  return (
    <section {...storyblokEditable(component)} className="w-full  relative">
      {component.background && (
        <Image
          className="pointer-events-none object-cover absolute w-full h-full  z-0"
          fill
          src={component.background.filename}
          alt={component.background.alt ?? ''}
        />
      )}
      <div className="flex flex-col justify-center items-center relative z-10 px-4 md:px-20 lg:max-w-screen-lg lg:mx-auto pt-20 md:pt-40 pb-28 md:pb-48">
        {component.preTitleImage && (
          <Image
            src={component.preTitleImage.filename}
            alt={component.preTitleImage.alt ?? ''}
            {...getImageDimensions(component.preTitleImage)}
          />
        )}
        {component.title && (
          <h1 className="text-title text-center font-[550] mt-6">
            {render(
              component.title,
              makeRichTextRenderOptions(story, {
                markResolvers: {
                  [MARK_BOLD]: children => <span className={clsx(' text-brandBlue-400', lora.className)}>{children}</span>,
                },
              }),
            )}
          </h1>
        )}
        {component.subtitle && <p className="text-center text-neutral-darker text-lg mt-8">{component.subtitle}</p>}
        {cta && (
          <Link
            className="mt-20 transition-colors text-white bg-brandBlue-700 hover:bg-brandBlue-800 active:bg-brandBlue-800 font-semibold px-14 py-4 rounded-lg text-lg"
            {...storyblokEditable(cta)}
            href={resolveWebLink(cta.link, { story, component: cta })}
          >
            {cta.label}
          </Link>
        )}
      </div>
    </section>
  );
};
