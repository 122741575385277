import { NewsletterBannerStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import Link from 'next/link';
import clsx from 'clsx';
import { NewsletterBannerForm, NewsletterBannerContainer } from './form';
import { storyblokEditable } from '@storyblok/js';

export const StoryblokNewsletterBanner: StoryblokReactComponent<NewsletterBannerStoryblok> = ({ component, story }) => {
  return (
    <NewsletterBannerContainer component={component} story={story}>
      <section
        className={clsx(
          'relative px-4 py-10 text-white rounded-xl mx-4 md:mx-20 xl:mx-auto max-w-section overflow-hidden',
          component.background === 'blue' && 'bg-brandBlue-400',
          component.background === 'green' && 'bg-brandGreen-400',
        )}
        {...storyblokEditable(component)}
      >
        <LeftBgOverlay className="absolute left-0 top-0 h-full" />
        <div className="relative z-10 top-0 left-0 flex flex-col md:w-8/12 md:mx-auto md:max-w-lg lg:max-w-xl xl:max-w-3xl">
          <h2 className="text-center text-3xl lg:text-5xl lg:leading-tight font-medium">{component.title}</h2>
          {component.subtitle && <p className="text-center mt-3 text-lg lg:text-xl leading-snug">{component.subtitle}</p>}{' '}
          <NewsletterBannerForm component={component} story={story} />
          <p className="text-center text-xs">
            *Iscrivendoti dichiari di aver attentamente letto ed esaminato la nostra{' '}
            <Link className="underline" href="/privacy-policy">
              informativa sulla privacy
            </Link>
          </p>
        </div>
        <RightBgOverlay className="absolute right-0 top-0 h-full hidden md:block" />
      </section>
    </NewsletterBannerContainer>
  );
};

const LeftBgOverlay = ({ className }: { className?: string }) => (
  <svg width="217" height="415" viewBox="0 0 217 415" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-53.7433 51.7421C-51.6628 54.8536 -49.112 57.7263 -46.1003 60.2534C-44.324 61.7439 -42.4573 63.0556 -40.5219 64.1904C-26.1136 72.9498 -7.14714 72.0421 6.48667 60.602C10.323 57.3829 13.4115 53.6031 15.7326 49.4832L61.4703 -5.02484C75.8279 -22.1356 73.596 -47.6457 56.4853 -62.0033C39.3746 -76.3609 13.8644 -74.1291 -0.49321 -57.0183L-19.953 -33.827L-39.1204 -56.6698C-53.478 -73.7806 -78.9882 -76.0124 -96.0989 -61.6548C-113.21 -47.2972 -115.441 -21.787 -101.084 -4.67627L-53.7433 51.7421Z"
      fill="#F1F7F6"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-53.7433 363.742C-51.6628 366.854 -49.112 369.726 -46.1003 372.253C-44.324 373.744 -42.4573 375.056 -40.5219 376.19C-26.1136 384.95 -7.14714 384.042 6.48667 372.602C10.323 369.383 13.4115 365.603 15.7326 361.483L61.4703 306.975C75.8279 289.864 73.596 264.354 56.4853 249.997C39.3746 235.639 13.8644 237.871 -0.49321 254.982L-19.953 278.173L-39.1204 255.33C-53.478 238.219 -78.9882 235.988 -96.0989 250.345C-113.21 264.703 -115.441 290.213 -101.084 307.324L-53.7433 363.742Z"
      fill="#F1F7F6"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.494804 209.268C13.8628 226.379 39.373 228.61 56.4837 214.253C73.5944 199.895 75.8262 174.385 61.4686 157.274L14.1281 100.856C12.0476 97.7444 9.4968 94.8717 6.48506 92.3445C4.70871 90.854 2.84183 89.5423 0.906303 88.4074C-13.5019 79.6482 -32.4682 80.556 -46.1019 91.996C-49.9383 95.2151 -53.0267 98.9948 -55.3479 103.115L-101.086 157.623C-115.443 174.734 -113.211 200.244 -96.1005 214.601C-78.9898 228.959 -53.4796 226.727 -39.122 209.616L-19.6622 186.425L-0.494804 209.268Z"
      fill="#F1F7F6"
    />
    <g opacity="0.7">
      <rect
        opacity="0.2"
        x="25.5039"
        y="552.25"
        width="159.595"
        height="80.8876"
        rx="40.4438"
        transform="rotate(-130 25.5039 552.25)"
        fill="#F1F7F6"
      />
      <rect
        opacity="0.2"
        x="37.4668"
        y="430.342"
        width="159.595"
        height="80.8876"
        rx="40.4438"
        transform="rotate(130 37.4668 430.342)"
        fill="#F1F7F6"
      />
    </g>
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.505 110.269C159.863 127.38 185.373 129.611 202.484 115.254C219.594 100.896 221.826 75.3859 207.469 58.2752L160.128 1.85692C158.048 -1.25465 155.497 -4.12732 152.485 -6.65448C150.709 -8.14501 148.842 -9.45676 146.906 -10.5916C132.498 -19.3508 113.532 -18.443 99.8981 -7.00301C96.0617 -3.78391 92.9733 -0.00417519 90.6521 4.11583L44.9145 58.6238C30.5569 75.7345 32.7887 101.245 49.8995 115.602C67.0102 129.96 92.5204 127.728 106.878 110.617L126.338 87.426L145.505 110.269Z"
      fill="#F1F7F6"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.505 422.268C159.863 439.379 185.373 441.61 202.484 427.253C219.594 412.895 221.826 387.385 207.469 370.274L160.128 313.856C158.048 310.744 155.497 307.872 152.485 305.345C150.709 303.854 148.842 302.542 146.906 301.407C132.498 292.648 113.532 293.556 99.8981 304.996C96.0617 308.215 92.9733 311.995 90.6521 316.115L44.9145 370.623C30.5569 387.734 32.7887 413.244 49.8995 427.601C67.0102 441.959 92.5204 439.727 106.878 422.616L126.338 399.425L145.505 422.268Z"
      fill="#F1F7F6"
    />
  </svg>
);

const RightBgOverlay = ({ className }: { className?: string }) => (
  <svg width="199" height="415" viewBox="0 0 199 415" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g opacity="0.7">
      <rect
        opacity="0.2"
        x="282.5"
        y="552.25"
        width="159.595"
        height="80.8876"
        rx="40.4438"
        transform="rotate(-130 282.5 552.25)"
        fill="#F1F7F6"
      />
      <rect
        opacity="0.2"
        x="294.463"
        y="430.342"
        width="159.595"
        height="80.8876"
        rx="40.4438"
        transform="rotate(130 294.463 430.342)"
        fill="#F1F7F6"
      />
    </g>
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M271.126 100.857C269.046 97.745 266.495 94.8724 263.483 92.3452C261.707 90.8548 259.84 89.5431 257.905 88.4083C243.496 79.6488 224.53 80.5565 210.896 91.9967C207.06 95.2158 203.971 98.9955 201.65 103.115L155.913 157.623C141.555 174.734 143.787 200.244 160.898 214.602C178.008 228.96 203.518 226.728 217.876 209.617L237.336 186.426L256.503 209.268C270.861 226.379 296.371 228.611 313.482 214.253C330.592 199.896 332.824 174.386 318.467 157.275L271.126 100.857Z"
      fill="#F1F7F6"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M217.88 255.331C203.522 238.22 178.012 235.988 160.901 250.346C143.79 264.703 141.559 290.214 155.916 307.324L203.257 363.743C205.337 366.854 207.888 369.727 210.9 372.254C212.676 373.745 214.543 375.056 216.478 376.191C230.887 384.95 249.853 384.043 263.487 372.603C267.323 369.384 270.411 365.604 272.733 361.484L318.47 306.976C332.828 289.865 330.596 264.355 313.485 249.997C296.375 235.64 270.864 237.872 256.507 254.982L237.047 278.174L217.88 255.331Z"
      fill="#F1F7F6"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M217.88 -56.6692C203.522 -73.7799 178.012 -76.0117 160.901 -61.6541C143.79 -47.2965 141.559 -21.7863 155.916 -4.6756L203.257 51.7427C205.337 54.8543 207.888 57.7269 210.9 60.2541C212.676 61.7446 214.543 63.0564 216.478 64.1912C230.887 72.9504 249.853 72.0427 263.487 60.6026C267.323 57.3835 270.411 53.6038 272.733 49.4838L318.47 -5.02419C332.828 -22.1349 330.596 -47.6451 313.485 -62.0027C296.375 -76.3603 270.864 -74.1284 256.507 -57.0177L237.047 -33.8264L217.88 -56.6692Z"
      fill="#F1F7F6"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.8796 354.331C57.522 337.22 32.0118 334.988 14.9011 349.346C-2.20966 363.703 -4.44148 389.214 9.91612 406.324L57.2567 462.743C59.3372 465.854 61.888 468.727 64.8997 471.254C66.6761 472.745 68.5429 474.056 70.4785 475.191C84.8867 483.95 103.853 483.043 117.487 471.603C121.323 468.384 124.411 464.604 126.733 460.484L172.47 405.976C186.828 388.865 184.596 363.355 167.485 348.997C150.375 334.64 124.864 336.872 110.507 353.982L91.047 377.174L71.8796 354.331Z"
      fill="#F1F7F6"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.8796 42.3308C57.522 25.2201 32.0118 22.9883 14.9011 37.3459C-2.20966 51.7035 -4.44148 77.2137 9.91612 94.3244L57.2567 150.743C59.3372 153.854 61.888 156.727 64.8997 159.254C66.6761 160.745 68.5429 162.056 70.4785 163.191C84.8867 171.95 103.853 171.043 117.487 159.603C121.323 156.384 124.411 152.604 126.733 148.484L172.47 93.9758C186.828 76.8651 184.596 51.3549 167.485 36.9973C150.375 22.6397 124.864 24.8716 110.507 41.9823L91.047 65.1736L71.8796 42.3308Z"
      fill="#F1F7F6"
    />
  </svg>
);
