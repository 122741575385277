import { PostStoryblok, SectionStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import Image from 'next/image';
import { getImageDimensions } from '@/storyblok/utils/asset';
import { richTextToString } from '@/storyblok/utils/richText';
import Link from 'next/link';

const WORDS_PER_MINUTE = 200;

export const PostSubtitle: StoryblokReactComponent<PostStoryblok> = ({ component, story }) => {
  if (component.author === undefined || typeof component.author === 'string') {
    throw new Error('Author relation not loaded');
  }

  const wordCount = Math.floor(
    (component.body
      ?.filter((blok): blok is SectionStoryblok => blok.component === 'section')
      .map(section => richTextToString(section.body))
      .join(' ')
      .split(/\s+/).length || 1) / WORDS_PER_MINUTE,
  );

  const author = component.author.content;
  const reviewer = typeof component.reviewer === 'object' && component.reviewer !== null ? component.reviewer.content : undefined;

  return (
    <div className="flex flex-col md:flex-row md:justify-start items-center gap-2 col-start-1 col-span-2 font-normal text-sm">
      <div className="flex flex-row items-center gap-2">
        <Link href={`/guide/autori/${component.author.slug}`}>
          <Image
            className="h-5 w-5 rounded-full"
            src={author.image.filename}
            alt={author.image.alt ?? ''}
            {...getImageDimensions(author.image)}
          />
        </Link>
        <Link href={`/guide/autori/${component.author.slug}`}>
          <span className="text-neutral-lighter">{author.name}</span>
        </Link>
      </div>
      {reviewer && <span className="text-neutral-lighter">Revisionato da: {reviewer.name}</span>}
      <span className="text-neutral-lighter" role="separator">
        ~
      </span>
      <span className="text-neutral-lighter">
        {new Date(story.first_published_at ?? story.published_at).toLocaleDateString(undefined, {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        })}
      </span>
      <span className="hidden md:inline-block text-neutral-lighter" role="separator">
        ~
      </span>
      <div className="flex flex-row items-center gap-1">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 10.0001C2.5 6.08796 5.67121 2.91675 9.58331 2.91675C13.4954 2.91675 16.6666 6.08796 16.6666 10.0001C16.6666 13.9122 13.4954 17.0834 9.58331 17.0834C5.67121 17.0834 2.5 13.9122 2.5 10.0001ZM9.58331 4.20462C6.38248 4.20462 3.78788 6.79923 3.78788 10.0001C3.78788 13.2009 6.38248 15.7955 9.58331 15.7955C12.7841 15.7955 15.3788 13.2009 15.3788 10.0001C15.3788 6.79923 12.7841 4.20462 9.58331 4.20462Z"
            fill="#627282"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.58583 5.49255C9.94147 5.49255 10.2298 5.78085 10.2298 6.13649V9.73663L12.7712 12.2783C13.0226 12.5298 13.0226 12.9375 12.7711 13.189C12.5196 13.4404 12.1119 13.4404 11.8604 13.1889L9.13047 10.4586C9.00973 10.3379 8.94189 10.1741 8.94189 10.0033V6.13649C8.94189 5.78085 9.2302 5.49255 9.58583 5.49255Z"
            fill="#627282"
          />
        </svg>
        <span className="text-neutral-lighter">{wordCount} minuti</span>
      </div>
    </div>
  );
};
