'use client';

import { Step123ItemStoryblok, Step123Storyblok, StoryblokComponent } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { storyblokEditable } from '@storyblok/js';
import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';
import clsx from 'clsx';
import Link from 'next/link';
import { resolveWebLink } from '../utils/link';
import React from 'react';
import { StoryblokStory } from 'storyblok-generate-ts';

export const StoryblokStep123: StoryblokReactComponent<Step123Storyblok> = ({ component, story }) => {
  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' ? window.matchMedia('(max-width: 1080px)').matches : true);
  const [selectedStepUid, setSelectedStepUid] = useState(component.steps?.at(0)?._uid);
  const stepRefs = useRef<{ [key: string]: { el: HTMLDivElement | null; openHeight: number } }>({});
  const [stepHeight, setStepHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    window.matchMedia('(max-width: 1080px)').addEventListener('change', e => setIsMobile(e.matches));
    if (!isMobile) return;
    const { key, height } = Object.entries(stepRefs.current).reduce(
      (acc, [key, { el }]) => ((el?.scrollHeight ?? 0) > acc.height ? { key, height: el?.scrollHeight ?? 0 } : acc),
      { key: '', height: 0 },
    );
    stepRefs.current[key] = { ...stepRefs.current[key], openHeight: height };
    setStepHeight(height);
  }, [isMobile, selectedStepUid]);

  return (
    <>
      <section className="flex-col max-w-section mx-auto hidden lg:flex lg:mx-20 xl:mx-auto" {...storyblokEditable(component)}>
        {component.title && <h2 className="text-5xl text-center font-[550]">{component.title}</h2>}
        {component.subtitle && <p className="text-xl text-center text-neutral-lighter mt-6 font-[400]">{component.subtitle}</p>}
        <div
          className={clsx(
            'flex flex-col items-center shadow-2 border rounded-xl py-12',
            (component.title || component.subtitle) && 'mt-10',
          )}
        >
          <nav className="w-full px-6">
            <ul className="grid w-full" style={{ gridTemplateColumns: `repeat(${component.steps?.length ?? 0}, 1fr)` }}>
              {(component.steps ?? []).map(step => (
                <li key={step._uid} className="flex-grow shrink-0">
                  <button
                    className={clsx(
                      'flex flex-row items-center gap-2 justify-center px-2  py-4 border-b-2 transition-colors text-left w-full text-xl',
                      selectedStepUid === step._uid ? 'bg-brandBlue-50 border-brandBlue-400' : 'bg-white border-neutral-100',
                    )}
                    onClick={() => setSelectedStepUid(step._uid)}
                  >
                    {step.icon?.filename && (
                      <Image
                        className={clsx(selectedStepUid !== step._uid && 'grayscale', 'size-5')}
                        src={step.icon.filename}
                        alt={step.icon.alt ?? ''}
                        {...getImageDimensions(step.icon)}
                      />
                    )}
                    {step.title && <span>{step.title}</span>}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          {(component.steps ?? []).map(step => (
            <div key={step._uid} className={clsx('hidden', selectedStepUid !== step._uid ? 'lg:hidden' : 'lg:flex flex-row pt-16 w-full')}>
              <div className="flex flex-col justify-center gap-6 max-w-96 pl-14">
                {step.preTitle && <span className="font-semibold">{step.preTitle}</span>}
                {(step.tabTitle || step.title) && <h2 className="text-4xl font-[550]">{step.title ?? step.tabTitle}</h2>}
                {step.description && <p>{step.description}</p>}
                {step.cta?.[0] && (
                  <div className="flex flex-row mt-6">
                    <Link
                      className="bg-primary hover:bg-primary-hover transition-colors text-white py-4 px-16 font-medium text-center rounded-lg"
                      href={resolveWebLink(step.cta[0].link, { story })}
                    >
                      {step.cta[0].label}
                    </Link>
                  </div>
                )}
              </div>
              <div className="lg:h-[320px] xl:h-[420px] flex flex-row justify-end flex-grow pr-14">
                <div className="bg-neutral-inactive rounded-xl max-h-full">
                  {step.image && (
                    <Image
                      className="h-full w-auto rounded-xl"
                      src={step.image?.filename}
                      alt={step.image?.alt ?? ''}
                      {...getImageDimensions(step.image)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section ref={containerRef} className="lg:hidden mx-4 md:mx-20">
        {component.title && <h2 className="text-4xl font-[550]">{component.title}</h2>}
        {component.subtitle && <p className="text-xl text-neutral-lighter mt-6 font-[400]">{component.subtitle}</p>}
        <ul className={clsx((component.title || component.subtitle) && 'mt-12')}>
          {component.steps?.map(step => (
            <li key={step._uid} className="mb-4">
              <button className="flex flex-row w-full p-4 items-center text-xl" onClick={() => setSelectedStepUid(step._uid)}>
                {step.icon?.filename && (
                  <Image className="size-5" src={step.icon.filename} alt={step.icon.alt ?? ''} {...getImageDimensions(step.icon)} />
                )}
                {step.title && <span className="ml-4">{step.title}</span>}
                <div className="flex-grow flex flex-row justify-end">
                  {step._uid === selectedStepUid ? (
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.75 12C5.75 11.5858 6.08579 11.25 6.5 11.25L18.5 11.25C18.9142 11.25 19.25 11.5858 19.25 12C19.25 12.4142 18.9142 12.75 18.5 12.75L6.5 12.75C6.08579 12.75 5.75 12.4142 5.75 12Z"
                        fill="#5182EF"
                      />
                    </svg>
                  ) : (
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 3.75C12.9142 3.75 13.25 4.08579 13.25 4.5V11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H13.25V19.5C13.25 19.9142 12.9142 20.25 12.5 20.25C12.0858 20.25 11.75 19.9142 11.75 19.5V12.75H5C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H11.75V4.5C11.75 4.08579 12.0858 3.75 12.5 3.75Z"
                        fill="#5182EF"
                      />
                    </svg>
                  )}
                </div>
              </button>
              <MobileStepContent
                selected={selectedStepUid === step._uid}
                story={story}
                step={step}
                key={step._uid}
                height={stepRefs.current[step._uid]?.openHeight || stepHeight}
                ref={el => {
                  stepRefs.current[step._uid] = { el, openHeight: el?.scrollHeight ?? 0 };
                }}
              />
              <div className="border-b-2 border-neutral-100" />
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

const MobileStepContent = React.forwardRef<
  HTMLDivElement,
  { selected: boolean; step: Step123ItemStoryblok; height: number; story: StoryblokStory<StoryblokComponent> }
>(({ step, selected, height, story }, ref) => (
  <div
    ref={ref}
    className={clsx('transition-all duration-500 flex flex-col overflow-hidden gap-5 ')}
    style={{ maxHeight: selected ? height : '0', paddingBottom: !selected ? 0 : undefined }}
  >
    {!step.textBeforeImageOnMobile && step.image && (
      <Image className="w-full rounded-xl" src={step.image.filename} alt={step.image.alt ?? ''} {...getImageDimensions(step.image)} />
    )}
    <div className="py-2">
      {step.preTitle && <span className="font-semibold text-xs mb-1 inline-block">{step.preTitle}</span>}
      {(step.tabTitle || step.title) && <h2 className="text-2xl font-[550] mb-3">{step.title ?? step.tabTitle}</h2>}
      {step.description && <p className="text-neutral-lighter">{step.description}</p>}
      {step.cta?.[0] && (
        <div className="flex flex-row mt-6">
          <Link
            className="bg-primary hover:bg-primary-hover transition-colors text-white py-4 px-16 font-medium text-center rounded-lg"
            href={resolveWebLink(step.cta[0].link, { story })}
          >
            {step.cta[0].label}
          </Link>
        </div>
      )}
    </div>
    {step.textBeforeImageOnMobile && step.image && (
      <Image className="w-full rounded-xl" src={step.image.filename} alt={step.image.alt ?? ''} {...getImageDimensions(step.image)} />
    )}
    <div className="h-6" />
  </div>
));
MobileStepContent.displayName = 'MobileStepContent';
